<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ $t('broadcasts.broadcastStatistic') }}</span>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <!-- <span class="font-weight-semibold text--primary me-1">{{ $t('Date') }} :</span>
      <span>{{ dateRangeText }}</span> -->
      <v-row class="justify-space-between">
        <v-card-actions>
          <v-menu
            ref="menu"
            v-model="menu"
            :disabled="!serverConnectionStatus"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            bottom
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- <span class="font-weight-semibold text--primary me-1">{{ $t('Date') }} :</span>
          <span>{{ dateRangeText }}</span> -->
              <v-text-field
                :label="$t('broadcasts.date')"
                :prepend-icon="icons.mdiCalendarMonth"
                :value="dateRangeText"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
              <!-- <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>
              {{ icons.mdiCalendarMonth }}
            </v-icon>
          </v-btn> -->
            </template>
            <v-date-picker v-model="dates" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="#ffeeee" @click="menu = false">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>
              <v-btn text color="success" @click="getStatistic(dates)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
          <v-icon class="ml-2" style="cursor: pointer" @click="resetDates">{{ icons.mdiClose }}</v-icon>
        </v-card-actions>
      </v-row>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="2" class="d-flex align-center">
          <v-avatar size="44" :color="data.color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-2">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title class="align-start">
      <span class="font-weight-semibold"></span>
    </v-card-title>

    <div v-if="isLoadingStats" class="loading-indicator">
      <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-card-text v-if="!isLoadingStats">
      <v-row>
        <v-col cols="12">
          <vue-apex-charts type="area" height="400" :options="newChartOptions" :series="newChartData" />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="450">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import { default as clientPocketBase } from '@/helpers/pocketbase'
import {
  mdiBallot,
  mdiBookOpen,
  mdiCalendarMonth,
  mdiClose,
  mdiEmailReceive,
  mdiMessageAlert,
  mdiReplyAll,
  mdiSend,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import pouchDbMixin from '../../mixins/pouchDbMixin'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      // icons
      icons: {
        mdiCalendarMonth,
        mdiMessageAlert,
        mdiSend,
        mdiEmailReceive,
        mdiBookOpen,
        mdiReplyAll,
        mdiBallot,
        mdiClose,
      },
    }
  },
  async mounted() {
    // cek token life
    this.getStatistic()
    this.loadingDialog = false
  },
  mixins: [pouchDbMixin],
  data() {
    return {
      dates: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      chartData: [],
      detailDialog: false,
      detailBroadcast: {},
      menu: false,
      statisticsData: [],
      newChartData: [],
      newChartOptions: {},
      loadingDialog: false,
      loadingText: 'Preparing Chart Data',
      allBroadcast: [],
      totalStats: {
        sent: 0,
        failed: 0,
        received: 0,
        replied: 0,
        read: 0,
      },
      rangeDates: [],
      isLoadingStats: false,
    }
  },
  methods: {
    SelectGetStatistic(dates) {
      this.getStatistic(this.dateSend(dates))
      this.$refs.menu.save(dates)
    },
    resetDates() {
      this.dates = [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      this.getStatistic()
    },
    dateFormater(dt) {
      let formatDate = `${dt.getFullYear().toString().padStart(4, '0')}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`
      return formatDate
    },
    numbericdate(date) {
      return new Date(date).getTime() / 1000
    },
    dateSend(dateInput) {
      if (dateInput.length === 1) {
        let arr = JSON.parse(JSON.stringify(dateInput))
        arr.push(this.dateFormater(new Date(this.numbericdate(arr[0]) * 1000 - 70200000)))
        return arr
      } else {
        return dateInput
      }
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // console.log(dates, 'oooooooooooo')
      return dates
    },
    async getStatistic(dateRange) {
      this.$refs.menu.save(this.dates)
      this.isLoadingStats = true

      if (this.dates && this.dates.length === 2) {
        if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(this.dates[1]), new Date(this.dates[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(this.dates[0]), new Date(this.dates[1]))
        }
      } else {
        const startDate = `${new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getDate()}`
        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }

      try {
        console.log(
          'sayang ltime',
          new Date(this.dates[0]).getTime() - 25200000,
          new Date(this.dates[1]).getTime() + 61199000,
        )
        this.allBroadcast = await clientPocketBase.collection('crm_broadcasts').getFullList(200, {
          filter: ` last_update >= "${new Date(this.dates[0]).getTime() - 25200000}" && last_update <= "${
            new Date(this.dates[1]).getTime() + 61199000
          }"`,
          $autoCancel: false,
        })
        // console.log(this.allBroadcast)
      } catch (error) {
        console.log(error)
      }

      this.loadStatistic()
    },
    async loadStatistic() {
      this.menu = false
      const series = [
        { name: this.$t('broadcasts.sent'), data: [] },
        { name: this.$t('broadcasts.received'), data: [] },
        { name: this.$t('broadcasts.read'), data: [] },
        { name: this.$t('broadcasts.failed'), data: [] },
        { name: this.$t('broadcasts.replied'), data: [] },
      ]

      const tempDateBcStats = {}

      for (let i = 0; i < this.allBroadcast.length; i++) {
        const broadcast = this.allBroadcast[i]
        if (broadcast.last_update) {
          const { data } = await this.$axios.get(
            `${process.env.VUE_APP_WA_SERVICES_URL}broadcasts/get-broadcast-statistic/${broadcast.id}`,
          )
          const statsQuery = data.data
          const date = new Date(Number(broadcast.last_update))
          const year = date.getFullYear()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const key = `${year}-${month}-${day}`

          if (!tempDateBcStats[key]) {
            tempDateBcStats[key] = {
              sent: statsQuery.sent,
              failed: statsQuery.failed,
              read: statsQuery.read,
              received: statsQuery.received,
              replied: statsQuery.replied,
            }
            // console.log('sayang stats 1', statsQuery.read, tempDateBcStats['2023-03-14'])
          } else {
            tempDateBcStats[key].sent += statsQuery.sent
            tempDateBcStats[key].failed += statsQuery.failed
            tempDateBcStats[key].read += statsQuery.read
            tempDateBcStats[key].received += statsQuery.received
            tempDateBcStats[key].replied += statsQuery.replied
            // console.log('sayang stats 2', statsQuery.read, tempDateBcStats['2023-03-14'])
          }
        }
      }

      this.rangeDates.forEach(date => {
        if (tempDateBcStats.hasOwnProperty(date)) {
          series.forEach(serie => {
            serie.data.push(tempDateBcStats[date][serie.name.split('.').pop().toLowerCase()])
          })
        } else {
          series.forEach(serie => {
            serie.data.push(0)
          })
        }
      })

      this.newChartData = series
      this.newChartOptions = {
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        xaxis: {
          categories: JSON.parse(JSON.stringify(this.rangeDates)),
        },
      }
      this.isLoadingStats = false
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    locale() {
      this.loadStatistic()
    },
  },
}
</script>

<style>
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 15px;
}
</style>
